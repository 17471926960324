<template>
	<VChart 
		class="chart"
		:class="classes"
		:option="option" 
	/>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
// import { BarChart, LineChart, PieChart } from "echarts/charts";

import {
	// TitleComponent,
	TooltipComponent,
	// LegendComponent,
	GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
	CanvasRenderer,
	BarChart,
	// LineChart,
	// PieChart,
	// TitleComponent,
	TooltipComponent,
	// LegendComponent,
	GridComponent,
]);

export default {
	props: {
		option: {
			type: Object,
			required: true,
		},
		classes: {
			type: String,
			required: false,
			default: '',
		},
	},
	components: {
		VChart
	},
	provide: {
		[THEME_KEY]: "light"
	},
	
	computed: {
		// option() {
		// 	return {
		// 		xAxis: {
		// 			type: 'category',
		// 			data: this.dateRangeEnumerate,
		// 			// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		// 		},
		// 		yAxis: {
		// 			type: 'value',
		// 		},
		// 		series: [
		// 			{
		// 				// data: [150, 230, 224, 218, 135, 147, 260],
		// 				type: 'line',
		// 				data: [150, 230, 224, 218, 135, 147, 260],
		// 			},
		// 		],
		// 		color: [
		// 			'#2ebd59',
		// 		]
		// 	}
		// },
		optionPie() {
			return {
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [
					{
						name: 'Total stuff',
						type: 'pie',
						data: [
							{ value: Math.random(0,100)*1000, name: "Direct" },
							{ value: 310, name: "Email" },
						],
					},
				],
			}
		},
	},
	
	data() {
		return {
			// option: {
			// 	// title: {
			// 	// 	text: "Traffic Sources",
			// 	// 	left: "center"
			// 	// },
			// 	tooltip: {
			// 		trigger: "item",
			// 		formatter: "{a} <br/>{b} : {c} ({d}%)"
			// 	},
			// 	// legend: {
			// 	// 	orient: "vertical",
			// 	// 	left: "left",
			// 	// 	data: [
			// 	// 		"Direct",
			// 	// 		"Email",
			// 	// 		"Ad Networks",
			// 	// 		"Video Ads",
			// 	// 		"Search Engines"
			// 	// 	]
			// 	// },
			// 	series: [
			// 		{
			// 			name: "Traffic Sources",
			// 			type: "pie",
			// 			// radius: "100%",
			// 			// center: ["50%", "50%"],
			// 			data: [
			// 				{ value: 335, name: "Direct" },
			// 				{ value: 310, name: "Email" },
			// 				// { value: 234, name: "Ad Networks" },
			// 				// { value: 135, name: "Video Ads" },
			// 				// { value: 1548, name: "Search Engines" }
			// 			],
			// 			// emphasis: {
			// 			// 	itemStyle: {
			// 			// 		shadowBlur: 10,
			// 			// 		shadowOffsetX: 0,
			// 			// 		shadowColor: "rgba(0, 0, 0, 0.5)"
			// 			// 	}
			// 			// }
			// 		}
			// 	]
			// }
		};
	}
};
</script>
  
<!-- <style scoped>
.chart {
	height: 400px;
}
</style> -->